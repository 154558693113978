import React, { useEffect, useState } from "react";
import { useAppSelector} from "../../core/Redux/ReduxStore";
import { CommonUserProfile } from "../../features";
import { ApiSchemaProfileData, ApiSchemaUpdateUser, ApiSchemaUserDetailsData } from "../../models";
import { useFunctionalityUser2, useFunctionalityUserProfileInfo } from "../hooks";
import { shallowEqual, useDispatch } from "react-redux";
import { ActionAuth, VRIAppStateType } from "../../core";

export function PageProfile(): JSX.Element {
  const profile = useAppSelector((state: VRIAppStateType) => state.auth.profile);
  const [editModalShow, setEditModalShow] = useState(false);
  const { updateUserDetails } = useFunctionalityUser2({ userId: profile?.id });
  const { onUserDetailsData } = useFunctionalityUserProfileInfo({ userId: profile?.id });
  const dispatch = useDispatch();
  const [updatedUserDetailsData, setUpdatedUserDetailsData] = useState<ApiSchemaUserDetailsData>();
  const [onUserProfileSuccess, setOnUserProfileSuccess] = useState(false);

  const reduxProfileData = useAppSelector(
    (state: VRIAppStateType) => ({
      profile: state.auth.profile
    }),
    shallowEqual
  );

  const onUpdateHandler = (form: ApiSchemaUpdateUser) => {
    form = { ...form, id: updatedUserDetailsData?.id, gender: Number(form.gender) }
    updateUserDetails(form)
      .then(() => {
        if (form.id == reduxProfileData.profile?.id) {
          let copyProfileData = { ...reduxProfileData.profile, timeZone: form.timeZone };
          dispatch(
            ActionAuth.SetProfileData(
              copyProfileData as unknown as ApiSchemaProfileData
            )
          );
        }
        setOnUserProfileSuccess(prev => !prev);
        setEditModalShow(false);

      })
      .catch((e: any) => { console.error(e) })
  };

  useEffect(() => {
    onUserDetailsData()
      ?.then((res) => {
        setUpdatedUserDetailsData(res?.data);
      });
  }, [onUserProfileSuccess]);

  return (
    <>
      <CommonUserProfile
        profile={profile}
        userDetailsData={updatedUserDetailsData}
        onUpdateHandler={onUpdateHandler}
        editModalShow={editModalShow}
        setEditModalShow={setEditModalShow}
      />
    </>
  );
}
