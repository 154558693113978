import { toast } from "react-toastify";
import {
  AppRouteUi,
  CallType,
  SocketConstants,
  device,
  AppConstants,
  CallStatusType,
  SocketNotificationType,
} from "../../../configs";
import {
  ActionAudioCall,
  ActionVideoCall,
  ActionUi,
} from "../../../core";
import {
  CallSocketMessageModel,
  SocketNotificationModel,
} from "../../../models/socket";
import { logger } from "../../../utils";

interface PropsType {
  responseModel: SocketNotificationModel<CallSocketMessageModel>;
  currentUser: any;
  dispatch: any;
  history: any;
  setIsAccepted: any;
  onLogout: any;
}

export function CallRedirection({
  responseModel,
  currentUser,
  dispatch,
  history,
  setIsAccepted,
  onLogout,
}: PropsType) {
  console.log("CALLREDIRECTION HAS BEEN VISITED");
  if (currentUser.userRole == AppConstants.UserRoles.Consumer) return;
  if (responseModel.Action == SocketConstants.onIncoming) {

    if (responseModel.Data.ChatSid) {
      dispatch(ActionUi.SetCallerId(responseModel.Data.CallerId));
      dispatch(ActionUi.SetRoomId(responseModel.Data.RoomId));
      dispatch(ActionUi.SetReceiverId(responseModel.Data.ReceiverId));
      dispatch(ActionUi.SetGuid(responseModel.Data.RoomShareMap_GuidGenerated));
      if (
        responseModel.Data.CallType == CallType.Video &&
        responseModel.Data.ChatSid
      ) {
        dispatch(ActionUi.SetChatSid(responseModel.Data.ChatSid));
      }
      dispatch(
        ActionUi.SetReceiverDeviceId(responseModel.Data.ReceiverDeviceId ?? "")
      );
      dispatch(ActionUi.SetLanguageName(responseModel.Data.LanguageName ?? ""));
      dispatch(
        ActionUi.SetCallerDeviceId(responseModel.Data.CallerDeviceId ?? "")
      );
      dispatch(ActionUi.SetCallType(responseModel.Data.CallType ?? 0));
    }

    dispatch(ActionUi.SetCallerId(responseModel.Data.CallerId));
    dispatch(ActionUi.SetRoomId(responseModel.Data.RoomId));
    dispatch(ActionUi.SetGuid(responseModel.Data.RoomShareMap_GuidGenerated));
    dispatch(ActionUi.SetLanguageName(responseModel.Data.LanguageName ?? ""));
    dispatch(
      ActionUi.SetCallerDeviceId(responseModel.Data.CallerDeviceId ?? "")
    );
    dispatch(ActionUi.SetCallType(responseModel.Data.CallType ?? 0));
    dispatch(ActionUi.SetReceiverId(responseModel.Data.ReceiverId ?? ""));
    if (
      responseModel.Data.Status === CallStatusType.Initiated ||
      responseModel.Data.Status == CallStatusType.ParticipantInvited
    ) {
      if (
        responseModel.Data.CallType == CallType.Video ||
        responseModel.Data.CallType == CallType.VideoConference
      ) {
        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: CallStatusType.Initiated,
            current: CallStatusType.Ringing,
          })
        );

        //console.trace("Redirecting:");
        history.push({
          pathname: AppRouteUi.VideoIncomingCall.Root,
          state: {
            CallStatus: responseModel.Data.Status,
          },
        });
      }
    } else if (
      responseModel.Data.Status === CallStatusType.PreparingCallSummary
    ) {
      if (
        responseModel.Data.CallType == CallType.Video ||
        responseModel.Data.CallType == CallType.VideoConference
      ) {
        // console.log(`CallRedirection: preparingCallSummary call status setter is called at [${new Date().toISOString()}]`);

        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: CallStatusType.Established,
            current: CallStatusType.PreparingCallSummary,
          })
        );
        dispatch(
          ActionVideoCall.setCallSummaryInfo({
            roomId: responseModel.Data.RoomId,
          })
        );
      }
    } else if (responseModel.Data.Status === CallStatusType.Canceled) {
      if (responseModel.Data.CallType == CallType.Video) {
        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: 0,
            current: 0,
          })
        );
      } else if (responseModel.Data.CallType == CallType.Audio) {
        if (device) device.disconnectAll();
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: 0,
            current: CallStatusType.Canceled,
          })
        );
      }

      //console.trace("Redirecting:");
      history.push({
        pathname:
          currentUser.userRole === AppConstants.UserRoles.Operator
            ? AppRouteUi.CallLog.Root
            : AppRouteUi.Home.Root,
        state: {
          CallStatus: CallStatusType.Canceled,
        },
      });
    } else if (
      responseModel.Data.Status === CallStatusType.Completed
    ) {
      dispatch(
        ActionAudioCall.SetAudioCallStatus({
          prev: CallStatusType.Established,
          current: CallStatusType.Completed,
        })
      );
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Established,
          current: responseModel.Data.Status,
        })
      );
      setIsAccepted(false);

      //console.trace("Redirecting:");
      history.push({
        pathname:
          currentUser.userRole === AppConstants.UserRoles.Operator
            ? AppRouteUi.CallLog.Root
            : AppRouteUi.Home.Root,
        state: {
          callId:
            responseModel.Data.Status === CallStatusType.Completed
              ? responseModel.Data.RoomId
              : "",
        },
      });
    }
  } else if (responseModel.Action == SocketConstants.onReceived) {
    if (
      responseModel.Data.CallType === CallType.Audio &&
      responseModel.Data.Status === CallStatusType.Received
    ) {
      dispatch(ActionUi.SetReceiverId(responseModel.Data.ReceiverId));
      dispatch(
        ActionAudioCall.SetAudioCallStatus({
          prev: CallStatusType.Ringing,
          current: CallStatusType.Received,
        })
      );
      return;
    }
    if (
      !!currentUser.VRItoken &&
      responseModel.Data.Status === CallStatusType.Received
    ) {
      const path = window.location.pathname;
      if (
        !path.includes('video-outgoing-call') &&
        !path.includes('video-incoming-call')
      ) {
        logger({ message: 'Stopping to go call page from: ', data: path });
        return;
      }
      dispatch(ActionUi.SetCallerId(responseModel.Data.CallerId));
      dispatch(ActionUi.SetRoomId(responseModel.Data.RoomId));
      dispatch(ActionUi.SetGuid(responseModel.Data.RoomShareMap_GuidGenerated));
      dispatch(ActionUi.SetReceiverId(responseModel.Data.ReceiverId));
      if (
        responseModel.Data.CallType == CallType.Video &&
        responseModel.Data.ChatSid
      ) {
        dispatch(ActionUi.SetChatSid(responseModel.Data.ChatSid));
      }
      dispatch(
        ActionUi.SetReceiverDeviceId(responseModel.Data.ReceiverDeviceId ?? "")
      );
      dispatch(ActionUi.SetLanguageName(responseModel.Data.LanguageName ?? ""));
      dispatch(
        ActionUi.SetCallerDeviceId(responseModel.Data.CallerDeviceId ?? "")
      );
      dispatch(ActionUi.SetGuid(responseModel.Data.RoomShareMap_GuidGenerated));

      dispatch(ActionUi.SetCallType(responseModel.Data.CallType ?? 0));

      //console.trace("Redirecting:");
      history.push({
        pathname: AppRouteUi.VideoCall.Room.Load(responseModel.Data.RoomId),
        state: {
          CallStatus: responseModel.Data.Status,
        },
      });
    } else if (
      responseModel.Data.Status === CallStatusType.Rejected ||
      responseModel.Data.Status == CallStatusType.ParticipantRejected
    ) {
      dispatch(ActionUi.SetCallerId(responseModel.Data.CallerId));
      dispatch(ActionUi.SetRoomId(responseModel.Data.RoomId));
      dispatch(ActionUi.SetGuid(responseModel.Data.RoomShareMap_GuidGenerated));

      dispatch(ActionUi.SetReceiverId(responseModel.Data.ReceiverId));
      dispatch(
        ActionUi.SetReceiverDeviceId(responseModel.Data.ReceiverDeviceId ?? "")
      );
      if (responseModel.Data.CallType == CallType.Video) {
        if (responseModel.Data.Status == CallStatusType.ParticipantRejected) {
          toast.error("Participant has rejected the invitation");
        }
        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: CallStatusType.Rejected,
            current: CallStatusType.Searching,
          })
        );
      } else if (responseModel.Data.CallType == CallType.Audio) {
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: CallStatusType.Rejected,
            current: CallStatusType.Searching,
          })
        );
      }
      // //console.trace("Redirecting:");
      // history.push({
        //   pathname:
        //     data.userRole === AppConstants.UserRoles.Operator
        //       ? AppRouteUi.CallLog.Root
        //       : AppRouteUi.Home.Root,
        //   state: {
        //     CallStatus: responseModel.Data.Status,
        //   },
        // });
      } else if (
        responseModel.Data.CallType == CallType.Video &&
        responseModel.Data.Status == CallStatusType.ParticipantMissed
      ) {
        toast.error("Participant invitation has been missed");
      } else if (responseModel.Data.Status === CallStatusType.Ringing) {
        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: CallStatusType.Initiated,
            current: CallStatusType.Ringing,
          })
        );
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: CallStatusType.Initiated,
            current: CallStatusType.Ringing,
          })
        );
      } else if (responseModel.Data.Status === CallStatusType.Missed) {
        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: CallStatusType.Missed,
            current: CallStatusType.Searching,
          })
        );
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: CallStatusType.Missed,
            current: CallStatusType.Searching,
          })
        );
      } else if (responseModel.Data.Status === CallStatusType.Completed) {
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: CallStatusType.Established,
            current: CallStatusType.Completed,
          })
        );
        setIsAccepted(false);

        //console.trace("Redirecting:");
        history.push({
          pathname:
            currentUser.userRole === AppConstants.UserRoles.Operator
              ? AppRouteUi.CallLog.Root
              : AppRouteUi.Home.Root,
          state: {
            callId: responseModel.Data.RoomId,
          },
        });
      } else if (responseModel.Type == SocketNotificationType.OutBoundCall) {
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            current: responseModel.Data.Status,
          })
        );
      }
    } else if (responseModel.Action == SocketConstants.forceLogout) {
      onLogout(true);
    }
  }
