import { useEffect, useState } from "react";
import Select from "react-select";
import { CallInitiateTabs } from "../../configs/appConstants";
import { useAppSelector, VRIAppStateType} from "../../core";
import { useFunctionalityDirectCall } from "../../pages/hooks/functionalityDirectCall";
import useFunctionalityMediaPermissions from "../../pages/hooks/mediaPermission/functionalityMediaPermission";
import CallInitiateButton from "../Common/Components/CommonCall/callInitiateButton";
import './search.css';


export function DirectCall(): JSX.Element {
  const [selectedUserId, setSelectedUserId] = useState<any>();
  const [error, setError] = useState<string>('');
  const currentUserCompanyId = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.companyId,
  );

  const companyAccountStatus = useAppSelector((state: VRIAppStateType) => state.auth?.menuSettings?.accountStatus);

  useEffect(() => { }, [selectedUserId, error])

  const selectUserChangeHandler = (value: any) => {
    setSelectedUserId(value);
    setError('');
  };
  const { nameList } = useFunctionalityDirectCall(currentUserCompanyId ?? "");
  const [userNameList, setUserNameList] = useState<any>()

  const { checkMediaPermissions } = useFunctionalityMediaPermissions()

  const [isMediaAllowed, setIsMediaAllowed] = useState<boolean>(false)

  const checkPermission = async () => {
    if (await checkMediaPermissions()) {
      setIsMediaAllowed(true)
      return true
    } else {
      setIsMediaAllowed(false)
      return false
    }
  }

  // useEffect(() => {
  //   // if (selectedUserId)
  //     // checkPermission()
  // }, [selectedUserId])

  const currentUserId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.id)


  return (
    <form action="">
      <div className="form-row">
        <div className="form-group col-md-4 col-sm-12 col-12">
          <label htmlFor="" className="text-muted font-sz-12 required">
            User
          </label>

          {/* <SelectSearch
            options={[]}
            value={selectedUserId}
            // value={{label : this.state.selectedCountryLabel}}
            getOptions={(query) => {
              return new Promise((resolve, reject) => {
                AxiosAuth.get(PlatformApi.Users.GetNames(currentUserCompanyId,query))
                  .then(({ data }) => {
                    console.log("Direct Call User Data",data)
                    resolve(data?.data.map(({ id, name }: { id: any, name: any }) => ({ value: id, name: name })))
                  })
                  .catch(reject)
              })
            }}
            search
            placeholder="Select an user..."
            // filterOptions={(options) => {
            //   const filter = fuzzySearch(options);
            //   return (q) => filter(q).slice(0, 10)
            // }}
            emptyMessage="No user found"
            onChange={(value) => selectUserChangeHandler(value)}
          /> */}

          <input
            className="d-none"
            name="stateCode"
          />
          <Select
            className={`${error ? 'field-required' : ''}`}
            placeholder="Select a Name"
            isDisabled={nameList.length === 0 ? true : false}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={nameList.filter(user => user.value !== currentUserId)}
            onChange={(value) => {
              selectUserChangeHandler(value?.value)
            }}
          />
          {
            error && <span className="text-danger">{error}</span>
          }
        </div>
      </div>
      <CallInitiateButton
        callInitiateTab={CallInitiateTabs.directCall}
        directCallSelectedUserId={selectedUserId}
        companyAccountStatus={companyAccountStatus}
        error={error}
        setError={setError}
        // isMediaAllowed={isMediaAllowed}
        // setIsMediaAllowed = {setIsMediaAllowed}
      />
    </form>
  );
}

